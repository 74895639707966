<template>
  <div>
    <BuyerUsers v-if="company.buyer" />
    <SellerUsers v-else />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BuyerUsers from './Buyer/BuyerUsers';
import SellerUsers from './Seller/SellerUsers';

export default {
  name: 'CompanyUsers',
  components: {
    BuyerUsers,
    SellerUsers,
  },
  computed: {
    ...mapState('companies', ['company']),
  },
};
</script>
