<template>
  <div>
    <form @submit.prevent @keyup.enter="validateEmail">
      <b-field grouped class="column-direction-touch">
        <b-field
          expanded
          label="E-mail"
          :type="campoInvalido('userEmail', 'data') ? 'is-danger' : ''"
          :message="campoInvalido('userEmail', 'data') ? 'Insira um e-mail' : ''"
        >
          <b-input
            placeholder="Insira um e-mail"
            :disabled="disableFields"
            v-model="data.userEmail"
            @input="data.userEmail = data.userEmail.toLowerCase()"
          >
          </b-input>
        </b-field>

        <b-field>
          <b-button
            class="button is-primary is-fullwidth invite-button"
            :loading="loading"
            :disabled="disableFields"
            @click.prevent.stop="validateEmail()"
          >
            Convidar
          </b-button>
        </b-field>
      </b-field>
    </form>

    <hr />

    <b-table
      paginated
      scrollable
      class="table is-fullwidth seller-users-table"
      v-if="users.length"
      :card-layout="tablet"
      :data="users"
    >
      <b-table-column numeric label="ID" width="40" v-slot="props">
        {{ props.index + 1 }}
      </b-table-column>

      <b-table-column sortable field="name" label="Usuário" v-slot="props">
        <div v-if="props.row.pending">
          <strong>{{ props.row.invitationEmail }}</strong>
          <b-tag type="is-warning ml-1">Pendente</b-tag>
        </div>

        <div v-else>
          <strong>{{ props.row.name }}</strong>
          <small class="tag-email"
            ><i>{{ props.row.userEmail }}</i></small
          >
        </div>
      </b-table-column>

      <b-table-column centered sortable field="createdAt" label="Inserido em" v-slot="props">
        <div class="inserted-column">
          {{ new Date(props.row.createdAt).toLocaleDateString('pt-BR') }}
        </div>
      </b-table-column>

      <b-table-column centered sortable field="function" label="Função" v-slot="props">
        {{ props.row.function === 'ADMINISTRADOR' ? 'Administrador' : 'Membro' }}
      </b-table-column>

      <b-table-column centered label="Permissões" v-slot="props">
        <button
          class="button is-small is-primary is-outlined"
          v-if="showPermissionButton(props.row.id)"
          @click.prevent.stop="viewPermissions(props.row)"
        >
          Selecionar
        </button>
      </b-table-column>

      <b-table-column centered v-slot="props" :visible="!disableFields">
        <div class="seller-users-options">
          <b-tooltip v-if="props.row.pending" label="Copiar Convite">
            <button
              v-if="props.row.pending"
              class="button mr-1"
              @click.prevent.stop="copyInvitation(props.index)"
            >
              <b-icon icon="content-copy"></b-icon>
            </button>
          </b-tooltip>

          <b-tooltip v-if="props.row.function !== 'ADMINISTRADOR'" label="Excluir">
            <button
              class="button is-danger"
              :disabled="disable.delete"
              v-if="props.row.funcao !== 'ADMINISTRADOR'"
              @click.prevent.stop="
                deleteUser(props.row.companyId, props.row.companyUserId, props.row.pending)
              "
            >
              <b-icon icon="delete"></b-icon>
            </button>
          </b-tooltip>
        </div>
      </b-table-column>
    </b-table>

    <b-modal
      has-modal-card
      v-if="permissionsModal"
      :active.sync="permissionsModal"
      @permissions="savePermissions"
    >
      <AppPermissions
        :disable-checks="disableChecks || disableFields"
        :loading="loading"
        :permissions="permissions"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import AppPermissions from '@/components/AppPermissions';

import field from '@/mixins/formulario';

export default {
  name: 'SellerUsers',
  mixins: [field],
  components: {
    AppPermissions,
  },
  data() {
    const data = {
      userEmail: '',
    };

    const disable = {
      delete: false,
    };

    const permissions = {
      editCompany: false,
      editUsers: false,
      operateOffers: false,
      operateBiddings: false,
    };

    return {
      data,
      disable,
      permissions,
      permissionsModal: false,
      companyId: 0,
      companyUserId: 0,
      disableChecks: false,
      loading: false,
    };
  },
  validations: {
    data: {
      userEmail: { required },
    },
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    ...mapState('companies', ['company']),
    ...mapGetters('app', { tablet: 'tablet' }),
    ...mapGetters({
      users: 'getCompanyUsers',
      user: 'getUser',
      logged: 'getLogged',
    }),
    disableFields() {
      return (
        !this.logged ||
        !this.users.some(user => user.id === this.user.id && user.permissions.editUsers)
      );
    },
    userMember() {
      return this.users.some(user => user.id === this.user.id && user.function === 'MEMBRO');
    },
  },
  methods: {
    ...mapActions(['getUsers', 'inviteCompanyUser', 'deleteCompanyUser', 'saveCompanyPermissions']),
    showPermissionButton(userId) {
      return this.userMember ? userId === this.user.id : true;
    },
    viewPermissions(row) {
      this.disableChecks = row.function === 'ADMINISTRADOR' || this.userMember;
      this.companyId = row.companyId;
      this.companyUserId = row.companyUserId;
      this.permissions.editCompany = row.permissions.editCompany;
      this.permissions.editUsers = row.permissions.editUsers;
      this.permissions.operateOffers = row.permissions.operateOffers;
      this.permissions.operateBiddings = row.permissions.operateBiddings;
      this.permissionsModal = true;
    },
    async savePermissions(permissions) {
      this.loading = true;
      try {
        await this.saveCompanyPermissions({
          empresaId: this.companyId,
          id: this.companyUserId,
          ...permissions,
        });
        this.$alerta('Permissões salvas', 'is-success');
        this.$emitBus('close-modal');
      } catch (error) {
        this.$alerta('Erro ao salvar permissões', 'is-danger');
      }
      this.loading = false;
    },
    async validateEmail() {
      if (this.validar('data')) {
        const { userEmail } = this.data;
        const emailExists = this.users.some(
          user =>
            (user.invitationEmail &&
              user.invitationEmail.toLowerCase() === userEmail.toLowerCase()) ||
            (user.userEmail && user.userEmail.toLowerCase() === userEmail.toLowerCase()),
        );

        if (emailExists) {
          this.$alerta('E-mail já existente', 'is-danger', 3000);
        } else if (userEmail.includes('@') && userEmail.includes('.')) {
          this.inviteNewUser();
        } else {
          this.$alerta('Insira um e-mail válido', 'is-danger', 3000);
        }
      }
    },
    async inviteNewUser() {
      const userData = {
        invitationEmail: this.data.userEmail.trim(),
        companyId: this.company.id,
      };

      this.loading = true;
      try {
        await this.inviteCompanyUser(userData);
        this.$alerta('Usuário convidado', 'is-success');
        this.data.userEmail = '';
        this.$v.data.$reset();
      } catch (error) {
        if (
          error.response.status === 400 &&
          error.response.data.message === 'ValidationError' &&
          error.response.data.details.message === 'User is already a member with another role'
        ) {
          this.$alerta('Usuário participa de uma prefeitura', 'is-danger');
          return;
        }

        this.$alerta('Erro ao convidar novo usuário', 'is-danger');
      } finally {
        this.loading = false;
      }
    },
    async copyInvitation(id) {
      try {
        const url = `${window.origin}/cadastrar?convite=${this.users[id].invitation}`;
        await this.$copyText(url);
        this.$alerta('Convite copiado', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao copiar o convite', 'is-danger');
      }
    },
    async deleteUser(companyId, companyUserId, pending) {
      const proceed = await this.$confirmacao({
        message: `Tem certeza que deseja excluir o usuário?${
          pending ? ' O convite não poderá ser aceito.' : ''
        }`,
        cancelText: 'Cancelar',
        confirmText: 'Continuar',
        type: 'is-danger',
        hasIcon: true,
      });
      if (!proceed) {
        return;
      }

      try {
        this.disable.delete = true;
        await this.deleteCompanyUser({ companyId, companyUserId });
        this.$alerta('Usuário excluído', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao excluir usuário', 'is-danger');
      }
      this.disable.delete = false;
    },
  },
  created() {
    this.getUsers(this.company.id);
  },
};
</script>

<style lang="scss" scoped>
.inserted-column {
  min-width: 140px;
}

.invite-button {
  height: 100%;
}

.seller-users-options {
  min-width: 85px;
}

.tag-email {
  margin-left: 0.4rem;
}

@media (max-width: 768px) {
  .invite-button {
    height: unset;
    margin-top: 0.75rem;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .buyer-users-table {
    max-width: 69vw;
  }
}
</style>
